import image1 from "../assets/image11.png";
import image2 from "../assets/image22.png";
import image3 from "../assets/image23.jpg";

export const testimonialsData = [
  {
    image: image1,
    review:
      "I am working as a Software Tranning at NexGen iot Solution I have Good UnderStanding of JavaScript React JS and Html CSS  I am well Familer With Postman Git and Github. I have Done B.Tech CSE from Dr A.P.J Abudul Kalam Techinical University of Lucknow",
    name: 'Nishant Singh',
    status : 'Front-End Developer'
  },
  {
    image: image2,
    review: 'I am working as a Software Tranning at NexGen iot Solution I have Good UnderStanding of JavaScript Nodejs Express Js, MongoDb or Java I am well Familer With Postman Git and Github. I have Done MCA and BCA from Vinoba Bhave University Hazaribaz',
    name: 'Deepak Kumar',
    status: 'Backend Developer'
  },
  {
    image : image3,
    review:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Minima aspernatur quod voluptatem',
    name: 'Kapil Kumar',
    status: "Full Stack Developer"
  }
];
