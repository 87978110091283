import React from 'react';
import './App.css';
import Hero from './Components/Hero/Hero';
import Programs from './Components/Programs/Programs';
import Reason from './Components/Reason/Reason';
import Plans from './Components/Plans/Plans';
import Testimonials from './assets/Testimonials/Testimonials';
import Join from './Components/Join/Join';
import Footer from './Components/Footer/Footer';
function App() {
  return (
    <div className="App">
       <Hero/>
       <Programs/>
       <Reason/>
       <Plans/>
       <Testimonials/>
       <Join/>
       <Footer/>
    </div>
  );
}

export default App;
